




















































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import moment from "moment";

export default Vue.extend({
  data() {
    let headers = [
      { text: "Mã xe", value: "id" },
      { text: "Mã tờ khai", value: "export_register_id" },
      { text: "Chú ý", value: "xnk_note" },
      { text: "Công ty", value: "company" },
      { text: "Tổng thuế nhập khẩu", value: "total_export_import_tax" },
      { text: "Tông thuê VAT", value: "total_export_vat" },
      { text: "Trạng thái", value: "status" },
      { text: "Tông trị giá tính thuế", value: "total_export_taxed_amount" },
      { text: "Ngày khai", value: "export_register_time" },
      { text: "Ngày thông quan", value: "thq_time" },
      { text: "Ngày thanh khoản", value: "thk_time" },
      { text: "Chi phí xuất khẩu", value: "export_fee_export" },
      { text: "Chi phí nhập khẩu", value: "export_fee_import" },
      { text: "Xe Bích Thủy", value: "export_fee_transport" },
      { text: "Bốc xếp VN", value: "export_fee_unload_vn" },
      { text: "Phát sinh", value: "export_fee_extra" },
      { text: "Tổng chi phí", value: "export_fee_total" },
      { text: "Ghi chú", value: "export_note" },
      { text: this.$t("ACTIONS"), value: "actions", sortable: false }
    ];

    if (userManager.checkRole(["xnk", "cs"], true)) {
      headers = [
        { text: "Mã xe", value: "id" },
        { text: "Mã tờ khai", value: "export_register_id" },
        { text: "Chú ý", value: "xnk_note" },
        { text: "Tổng thuế nhập khẩu", value: "total_export_import_tax" },
        { text: "Tông thuê VAT", value: "total_export_vat" },
        { text: "Ngày khai", value: "export_register_time" },
        { text: "Ngày thông quan", value: "thq_time" },
        { text: "Ghi chú", value: "export_note" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false }
      ];
    }

    return {
      userManager,
      dialog: false,
      form: false,
      ruleRequired: value => !!value || this.$t("REQUIRED"),
      headers,
      options: {},
      search: "",
      currentDate: "",
      filterStatus: "",
      filterTruckId: "",
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      availableStatuses: [
        {
          value: "",
          text: "Tất cả"
        },
        {
          value: "gph",
          text: "Giải phóng hàng"
        },
        {
          value: "tq",
          text: "Thông quan"
        },
        {
          value: "tk",
          text: "Thanh khoản"
        }
      ],
      availableCompanyMap: {
        dd: "Đại Dương",
        hb: "Hoàng Bảo"
      },
      totalTotalExportTaxedAmount: null
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("CREATE_PACKAGE")
        : this.$t("EDIT");
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    currentDate() {
      this.initialize();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    }
  },
  methods: {
    async initialize() {
      this.loading = true;
      let m: any = 0;
      let time: any = {};
      const filters = [
        {
          key: "export_register_id",
          operator: "match",
          value: this.search
        }
      ];
      if (this.filterTruckId) {
        filters.push({
          key: "id",
          operator: "equal_to",
          value: this.filterTruckId
        });
      }
      if (this.currentDate) {
        m = moment(this.currentDate);
        time = {
          from: m.startOf("month").format("X"),
          to: m.endOf("month").format("X")
        };
        if (time.from) {
          filters.push({
            key: "vn_time",
            operator: ">=",
            value: time.from
          });
        }

        if (time.to) {
          filters.push({
            key: "vn_time",
            operator: "<=",
            value: time.to
          });
        }
      }
      if (this.filterStatus) {
        switch (this.filterStatus) {
          case "gph":
            filters.push({
              key: "export_register_time",
              operator: ">",
              value: 0
            });
            filters.push({
              key: "thq_time",
              operator: "=",
              value: 0
            });
            filters.push({
              key: "thk_time",
              operator: "=",
              value: 0
            });
            break;
          case "tq":
            filters.push({
              key: "thq_time",
              operator: ">",
              value: 0
            });
            filters.push({
              key: "thk_time",
              operator: "=",
              value: 0
            });
            break;
          case "tk":
            filters.push({
              key: "thk_time",
              operator: ">",
              value: 0
            });
            break;
        }
        this.totalTotalExportTaxedAmount = await apiClient.truckGetTotalTotalExportTaxedAmount(
          this.filterStatus,
          time.from,
          time.to
        );
      } else {
        this.totalTotalExportTaxedAmount = null;
      }
      const { items, count } = await apiClient.truckGetAll({
        options: this.options,
        filters
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const item = { ...this.editedItem };
      item.export_register_time = Math.round(
        new Date(item.export_register_time).getTime() / 1000
      );
      let fields = [];
      if (this.userManager.checkRole(["xnk", "cs"], true)) {
        fields = ["export_register_id", "export_register_time", "xnk_note"];
      } else if (this.userManager.checkRole("kt", true)) {
        fields = [
          "export_fee_export",
          "export_fee_import",
          "export_fee_transport",
          "export_fee_unload_vn",
          "export_fee_extra",
          "export_note"
        ];
      }
      const result = await apiClient.truckUpdate(item, fields);
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async commitThq(item) {
      const result = await apiClient.truckUpdate({
        id: item.id,
        thq_time: Math.round(new Date().getTime() / 1000)
      });
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async commitThk(item) {
      const result = await apiClient.truckUpdate({
        id: item.id,
        thk_time: Math.round(new Date().getTime() / 1000)
      });
      if (result) {
        this.close();
        await this.initialize();
      }
    }
  }
});
